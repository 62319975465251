import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "app-review"

  static values = {
    supportsNativeAppReview: Boolean,
    appStoreUrl: String,
    os: String,
  }

  request() {
    if (this.osValue === "ios") {
      window.location.href = this.appStoreUrlValue
    } else if (this.supportsNativeAppReviewValue) {
      this.send("requestReview", {})
    }
    else if (this.supportsNativeAppReviewValue) {
      this.send("requestReview", {})
    }
  }
}
